$prime: #059e00b6;

@import "style.scss";

.navbar-nav .nav-item .nav-link {
    color: white;
}

.call-to-action {
    color: white;
    a{
        color: white;
    }
}